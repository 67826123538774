<template>
    <section>
        <div class="row mx-0 py-3 px-5 border-bottom align-items-center">
            <p class="mb-0 text-muted2 f-18 f-600">
                Consultas más frecuentes
            </p>
            <div class="ml-auto buscar-preguntas" style="width:320px;">
                <el-input placeholder="Buscar Pregunta" v-model="input" class="w-100"/>
            </div>
        </div>
        <div class="overflow-auto custom-scroll" style="height: calc(100vh - 133px)">
            <div class="row mx-4 mt-4 pt-4">
                <p class="mb-0 text-muted2 f-600">
                    General
                </p>
            </div>
            <div v-for="(data, i) in consultas" :key="i" class="my-2">
                <div class="row mx-4 p-0 border bg-whitesmoke2 cr-pointer" style="border-radius:12px;" @click="data.collapse = !data.collapse ">
                    <p class="mb-0 f-600 mx-4 py-2">
                        Que es "YourMarket"?
                    </p>
                    <button class="btn ml-auto">
                        <i :class="data.collapse == false ? 'icon-angle-down' : 'icon-angle-up'"></i>
                    </button>
                </div>
                <div v-if="data.collapse" class="row mx-3 my-3">
                    "Mi tienda" es la App de delivery que lleva a la puerta de tu casa todo lo que necesites en menos de una hora. Para hacer tu pedido solo debes seleccionar los productos y "Mi tienda" buscará a las tiendas de tu barrio que están cerca de tu ubicación. Ellos lo preparan y te lo llevan hasta tu casa ¡Así de fácil!
                </div>
            </div>
            <div class="row mx-4 mt-4 pt-4">
                <p class="mb-0 text-muted2 f-600">
                    Entrega
                </p>
            </div>
            <div v-for="(data, i) in entrega" :key="i" class="my-2">
                <div class="row mx-4 p-0 border bg-whitesmoke2 cr-pointer" style="border-radius:12px;" @click="data.collapse = !data.collapse ">
                    <p class="mb-0 f-600 mx-4 py-2">
                        Que es "YourMarket"?
                    </p>
                    <button class="btn ml-auto">
                        <i :class="data.collapse == false ? 'icon-angle-down' : 'icon-angle-up'"></i>
                    </button>
                </div>
                <div v-if="data.collapse" class="row mx-3 my-3">
                    "Mi tienda" es la App de delivery que lleva a la puerta de tu casa todo lo que necesites en menos de una hora. Para hacer tu pedido solo debes seleccionar los productos y "Mi tienda" buscará a las tiendas de tu barrio que están cerca de tu ubicación. Ellos lo preparan y te lo llevan hasta tu casa ¡Así de fácil!
                </div>
            </div>
            <div class="row mx-4 mt-4 pt-4">
                <p class="mb-0 text-muted2 f-600">
                    Mi cuenta
                </p>
            </div>
            <div v-for="(data, i) in cuenta" :key="i" class="my-2">
                <div class="row mx-4 p-0 border bg-whitesmoke2 cr-pointer" style="border-radius:12px;" @click="data.collapse = !data.collapse ">
                    <p class="mb-0 f-600 mx-4 py-2">
                        Que es "YourMarket"?
                    </p>
                    <button class="btn ml-auto">
                        <i :class="data.collapse == false ? 'icon-angle-down' : 'icon-angle-up'"></i>
                    </button>
                </div>
                <div v-if="data.collapse" class="row mx-3 my-3">
                    "Mi tienda" es la App de delivery que lleva a la puerta de tu casa todo lo que necesites en menos de una hora. Para hacer tu pedido solo debes seleccionar los productos y "Mi tienda" buscará a las tiendas de tu barrio que están cerca de tu ubicación. Ellos lo preparan y te lo llevan hasta tu casa ¡Así de fácil!
                </div>
            </div>
        </div>
        <!-- No hay coincidencias -->
        <div v-if="coincidencias !=  null" class="row mx-0 align-content-center justify-center" style="height:calc(100vh - 140px)">
            <div class="col-auto ">
                <p class="mb-0 text-muted2 f-17 f-600">
                    No hemos encontrado coincidencias con tu búsqueda
                </p>
                <div class="row mx-0 justify-center f-60">
                    <!-- Cambiar icono -->
                    <i class="icon-promociones" />
                    <!-- Cambiar icono -->
                </div>
            </div>
        </div>
        <!-- No hay coincidencias -->
    </section>
</template>
<script>
export default {
    data(){
        return {
            coincidencias: null,
            input: '',
            consultas: [
                {
                    collapse: false
                },
                {
                    collapse: false
                },
            ],
             entrega: [
                {
                    collapse: false
                },
                {
                    collapse: false
                }
            ],
             cuenta: [
                {
                    collapse: false
                },
                {
                    collapse: false
                }
            ]
        }
    },
    methods: {
        /* FunctionCollapse( index ) {
            this.consultas[index].collapse = !this.consultas[index].collapse
        } */
    }
}
</script>
<style lang="scss">
.buscar-preguntas{
    .el-input{
        .el-input__inner{
            text-align: center;
            border-radius: 30px;
        }
    }
}
</style>
