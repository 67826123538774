<template>
    <section class="pt-4 pl-4 custom-scroll overflow-auto" style="height:calc(100vh - 180px;)">
        <p class="f-600 text-black f-22">
            Lugares operación
        </p>
        <div class="row mx-0 my-3">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-7 col-5 pl-0">
                <small class="pl-3 text-black">
                    Seleccione país
                </small>
                <el-select v-model="pais" placeholder="Seleccionar" size="medium" class="w-100" filterable remote :remote-method="searchCountry" :loading="loading" @change="postPais">
                    <el-option
                    v-for="item in paisesFilter"
                    :key="item.id"
                    :label="item.pais"
                    :value="item.id"
                    :disabled="item.disabled"
                    />
                </el-select>
            </div>
            <div class="col pt-3">
                <div class="row mx-0 mt-2">
                    <div v-for="(item, index) in countriesSelected" :key="index" class="box-select d-middle justify-content-between bg-white mr-2">
                        <img :src="item.bandera_firmada" width="28" height="20" class="obj-cover ml-1" />
                        <span class="mx-2">
                            {{ item.pais }}
                        </span>
                        <el-tooltip content="Eliminar ciudad" placement="bottom" effect="light">
                            <i class="icon-cancel f-15 cr-pointer" @click="deleteCountrySelected(item.id_proyecto)" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="countriesSelected.length > 0">
            <div v-for="(data, index) in countriesSelected" :key="index" class="row mx-0 mt-5 mb-5">
                <div class="col-12 px-0 d-middle f-600 f-18 text-black mb-3">
                    <img :src="data.bandera_firmada" width="51" height="32" class="obj-cover mx-1" />
                    {{ data.pais }}
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-7 col-5 pl-0 ml-5">
                    <small class="pl-3 text-black">
                        Seleccione estados
                    </small>
                    <el-select v-model="data.checked" placeholder="Seleccionar" size="medium" class="w-100" @change="selectedState(index)">
                        <el-option
                        v-for="item in data.queryStates.filter((item) => item.pais_id == data.id)"
                        :key="item.id"
                        :label="item.estado"
                        :value="item.id"
                        :disabled="item.thisSelected"
                        />
                    </el-select>
                </div>
           
                <div class="col pt-3">
                    <div class="row mx-0 mt-1">
                        <div v-for="(item, index1) in data.queryStates.filter((item) => item.pais_id == data.id && item.thisSelected == true)" :key="index1" class="box-select d-middle justify-content-between bg-white mr-2 mt-1">
                            <span class="mx-2">
                                {{ item.estado }}
                            </span>
                            <el-tooltip content="Eliminar estado" placement="bottom" effect="light">
                                <i class="icon-cancel f-15 cr-pointer" @click="deleteStateSelected(item, index, index1)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
             
                <div v-for="(item, index2) in data.queryStates.filter((item) => item.thisSelected == true)" :key="index2" class="col-12 px-0 mt-3 ml-5">
                    <div class="col-12 px-0 d-middle f-600 f-18 text-black">
                        <p class="text-general pl-3">{{ item.estado }}</p>
                    </div>
                    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-7 col-5 pl-0 mt-2">
                        <small class="pl-3 text-black">
                            Seleccione ciudades
                        </small>
                        <el-select v-model="item.checked" placeholder="Seleccionar" size="medium" filterable remote :remote-method="filteredCities" :loading="loading" class="w-100" @focus="idStateSelected = item.id" @change="postCity(item.checked)">
                            <el-option
                            v-for="city in citiesFiltered"
                            :key="city.id"
                            :label="city.ciudad"
                            :value="city.id"
                            :disabled="city.thisSelected"
                            />
                        </el-select>
                    </div>
                
                    <div class="col pt-3">
                        <div class="row mx-0 mt-2">
                            <div v-for="(item2, index3) in forCities(item.id)" :key="index3" class="box-select d-middle justify-content-between bg-white mr-2">
                                <span class="mx-2">
                                    {{ item2.ciudad }}
                                </span>
                                <el-tooltip content="Eliminar ciudad" placement="bottom" effect="light">
                                    <i class="icon-cancel f-15 cr-pointer" @click="deleteCity(item2.id_proyecto_ciudad)" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row mx-0 mt-5 mb-5">
                <div class="col">
                    <p>Actualmente no hay lugares en operación</p>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import Service from '~/services/proyectos/proyectos'

export default {
    data(){
        return{
            loading: false,
            paises: [],
            paisesFilter: [],
            idsCountries: [],
            countriesSelected: [],
            statesSelected: [],
            citiesFiltered: [],
            pais: null,
            idStateSelected: null,
        }
    },

    mounted(){
        this.getPaises();
    },

    methods:{
        async getPaises(texto= null){
            try {
                const params = {
                    id_proyecto: this.$usuario.proyecto,
                    texto 
                }
                const {data} = await Service.buscarPaises(params)
                this.paises = data.paises.data
                this.paisesFilter = data.paises.data;
                let countriesSelected = data.paises.data.filter((item) => item.disabled == true);
                if(countriesSelected.length > 0){
                    this.idsCountries = _.map(countriesSelected, 'id');
                    this.getStates();
                }
            } catch (e){
                this.error_catch(e)
            }
        },

        async getStates(){
            try {
                this.citiesFiltered = [];

                const params = {
                    idProject: Number(this.$usuario.proyecto),
                    countries: JSON.stringify(this.idsCountries)
                };
                const {data} = await Service.getStatesCountry(params);

                this.countriesSelected = data.result.map((e) => {
                    e.bandera_firmada = e.bandera_firmada;
                    e.id = e.id;
                    e.pais = e.pais;
                    e.queryStates = e.queryStates;
                    e.id_proyecto = this.paises.find((item) => parseInt(item.id) == parseInt(e.id)).id_proyecto;
                    return e;
                });
                
                this.getCitiesStates(data.idsStatesSelected);
            } catch(e){
                this.error_catch(e)
            }
        },

        async getCitiesStates(idsStatesSelected){
            try {
                const params = {
                    idProject: Number(this.$usuario.proyecto),
                    states: JSON.stringify(idsStatesSelected)
                };
                const {data} = await Service.getCitiesStates(params)
                this.statesSelected = data.result;
            } catch(e){
                this.error_catch(e)
            }
        },

        searchCountry(query){
            if (query !== ''){
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.paisesFilter = this.paises.filter(item => {
                        return item.pais.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.paisesFilter = this.paises;
            }
        },

        async postPais(){
            try {
                const model = {
                    id_proyecto: this.$usuario.proyecto,
                    id_pais:  this.pais 
                }
                const {data} = await Service.ingresarPais(model)
                this.getPaises();
                this.notificacion('Mensaje', 'Pais ingresado', 'success')
                this.pais = null;
            } catch (e){
                this.error_catch(e)
            }
        },

        async deleteCountrySelected(idProject){
            try {
                const {data} = await Service.deletePais(idProject)
                this.getPaises();
                this.notificacion('Mensaje', 'País eliminado', 'success');
            } catch(e){
                this.error_catch(e)
            }
        },

        filteredCities(query){
            if (query !== ''){
                if(query.length < 3)return;

                this.loading = true;
                let cities = this.statesSelected.find((item) => parseInt(item.id_estado) == parseInt(this.idStateSelected)).queryCities;
                
                setTimeout(() => {
                    this.loading = false;
                    this.citiesFiltered = cities.filter(item => {
                        return item.ciudad.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
                
            } else {
                this.citiesFiltered = [];
            }
        },

        selectedState(indexCountry){
            let dataCountry = this.countriesSelected[indexCountry];
            this.postState(dataCountry);
        },

        async postState(dataState){
            try {
                const model = {
                    id_proyecto: this.$usuario.proyecto,
                    id_estado:  dataState.checked
                }
                const {data} = await Service.ingresarEstado(model)
                this.getStates();
                this.notificacion('Mensaje', 'Estado asignado', 'success')
            } catch(e){
                this.error_catch(e)
            }
        },

        async postCity(idCity){
            try {
                const model = {
                    id_proyecto: this.$usuario.proyecto,
                    id_ciudad: idCity 
                }
                const {data} = await Service.ingresarCiudad(model)
                this.getStates();
                this.notificacion('Mensaje', 'Ciudad asignada', 'success')
            } catch (e){
                this.error_catch(e)
            }
        },

        async deleteStateSelected(dataState){
            try {
                const {data} = await Service.deleteState(this.$usuario.proyecto, dataState.id);
                if(data.exito){
                    this.getStates();
                    this.notificacion('Mensaje', 'El estado se ha eliminado', 'success');
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        async deleteCity(idProyectoCiudad){
            try {
                const {data} = await Service.eliminarLugar(idProyectoCiudad)
                this.getStates();
                this.notificacion('Mensaje', 'La ciudad se ha eliminado', 'success')
            } catch (e){
                this.error_catch(e)
            }
        },

        forCities(idEstado){
            if(!idEstado)return 0;
            let data = this.statesSelected.find((item) => parseInt(item.id_estado) == parseInt(idEstado));
            if(data){
                return data.queryCities.filter((item) => item.thisSelected == true);
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.box-select{
    min-width: 152px;
    height: 36px;
    border: 1px solid #DFE4E8;
    border-radius: 8px;
    color: #000000;
}
</style>