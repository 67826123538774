import axios from "axios"

const API = "manager-tiendas/permisos";
const API2 = "manager-tiendas/modulos";

const permisos = {
    listarProyectos: () => axios(`manager-tiendas/lista-proyectos`),
    listarPermisos: () => axios(`${API}/lista`),
    crearPermiso: (params) => axios.post(`${API}/crear`, params),
    editarPermiso: (params) => axios.put(`${API}/editar/${params.id}`, params),
    cambioPosicionPermisos: (params) => axios.post(`${API}/cambio-posicion`, params),
    eliminarPermiso: (id) => axios.delete(`${API}/eliminar/${id}`),
    
    
    //matriz
    listarUserPermisos: (idProyecto, idModulo) => axios(`${API}/lista-matriz/${idProyecto}/${idModulo}`),
    asignarPermisoMasivo: (params) => axios.post(`${API}/asigar-permiso-masivo`, params),
    asignarPermiso: (params) => axios.post(`${API}/asigar-permiso`, params),
    
    //modulos
    listarModulos: () => axios(`${API2}/lista`),
    crearModulo: (params) => axios.post(`${API2}/crear`, params),
    editarModulo: (params) => axios.put(`${API2}/editar/${params.id}`, params),    
    eliminarModulo: (id) => axios.delete(`${API2}/eliminar/${id}`),
    
}

export default permisos