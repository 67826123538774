<template>
    <section>
        <div class="row mx-0">
            <div class="border overflow-auto custom-scroll menu-prinicipal br-10" style="width:430px;">
                <div class="col-12 px-0">
                        <div
                        v-for="(menu, idx) in menus" :key="idx"
                        class="row mx-0 d-middle border-bottom cr-pointer"
                        style="height:60px;"
                        @click="$router.push({ name: menu.ruta })"
                        >
                            <div :class="`mx-2 px-1 h-80 br-5 ${menu.ruta==$route.name?'bg-gr-red':''}`" />
                            <i :class="`${menu.icon} f-22 text-gr-general`" />
                            <div class="col-9 tres-puntos f-15">
                                {{ menu.nombre }}
                            </div>
                            <i :class="`icon-angle-right f-18 ${menu.ruta==$route.name?'text-gr-red':''}`" />
                        </div>
                </div>
            </div>
            <div class="px-0 col border br-t-10 menu-prinicipal ml-3 w-content">
                <router-view />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data(){
        return {
            menus: [
                {
                    nombre: 'Aspecto correos Electronicos',
                    ruta: 'aspecto-correos',
                    icon: 'icon-email-newsletter'
                },
                {
                    nombre: 'Logos del Sistema',
                    ruta: 'logos-sistema',
                    icon: 'icon-brush'
                },
                {
                    nombre: 'Terminos y Condiciones',
                    ruta: 'terminos-condiciones',
                    icon: 'icon-book-open-variant'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.menu-prinicipal{
    height: calc(100vh - 58px);
    background: #fff;
}
</style>