import axios from 'axios'

const API = "feature-flags";

const ENDPOINTS = {
    getCarpetas(params){
        return axios.get(`${API}/carpetas/listado`,{params}) 
    },
    postCarpeta(model){
        return axios.post(`${API}/carpetas/guardar`, model)
    },
    getFeatureFlags(id){
        return axios.get(`${API}/${id}/listado`)
    },
    deleteCarpeta(id){
        return axios.delete(`${API}/${id}/carpeta/eliminar`)
    },
    putCarpeta(id, model){
        return axios.put(`${API}/${id}/carpeta/actualizar`,model)
    },
    postFlags(model){
        return axios.post(`${API}/guardar`, model)
    },
    deleteFlag(id){
        return axios.delete(`${API}/${id}/eliminar`)
    },
    putFlags(id, model){
        return axios.put(`${API}/${id}/actualizar`, model)
    },
    updateEstadoFlag(id, model){
        return axios.put(`${API}/${id}/actualizar/estado`, model)
    }
    //consultarFaqs: params => axios(`${API_PREFIX}/listado-faqs`, { params }),
    //crearFaq: payload => axios.post(`${API_PREFIX}/crear-faq`, payload),
    //actualizarFaq: payload => axios.put(`${API_PREFIX}/actualizar-faq`, payload),
    //eliminarFaq: payload => axios.delete(`${API_PREFIX}/${payload}/eliminar-faq`),
    //actualizarPosicion: payload => axios.put(`${API_PREFIX}/actualizar-posicion`,payload),
    //actualizarPosicionHijos: payload => axios.put(`${API_PREFIX}/actualizar-posicion-hijos`, payload)
}

export default ENDPOINTS
