<template>
    <!-- <modal ref="modalCrearFeature" titulo="Crear Feature Flag" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="postFlags"> -->
    <ValidationObserver ref="validacion" tag="section" class="pt-4">
        <div class="row mx-0 align-items-center mb-4">
            <i class="icon-back cr-pointer text-black f-22" />
            <div class="col-auto px-2 text-black f-600 f-18">
                Crear Feature Flags
            </div>
        </div>
        <div class="row mx-0 pl-4 mb-4">
            <div class="col-xl-5 col-lg-7 col-md-7 col-sm-7 col-7 px-0">
                <div class="row mx-0">
                    <div class="col-6 pr-2">
                        <small class="text-muted2 pl-3">
                            Nombre del permiso
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:60" name="Nombre">
                            <el-input v-model="model.nombre" placeholder="Nombre Feature" maxlength="60" size="medium" show-word-limit class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 pl-2">
                        <small class="text-muted2 pl-3">
                            Nombre sin espacios
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:60|espacios" name="Nombre sin espacios">
                            <el-input v-model="model.flag" placeholder="Nombre sin espacios" size="medium" maxlength="60" show-word-limit class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span><br />
                            <span v-if="!flagUnique" class="text-danger w-100 f-11">Este nombre ya pertenece a otro Feature Flag</span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-3">
                        <small class="text-muted2 pl-3">
                            Descripción
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="max:300" name="Descripción">
                            <el-input v-model="model.descripcion" type="textarea" placeholder="Descripción" :rows="4" size="medium" maxlength="300" show-word-limit class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <small class="text-muted2 pl-3">
                            Texto de apoyo
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="max:120" name="Texto de apoyo">
                            <el-input v-model="model.texto" type="textarea" :rows="4" placeholder="Texto de apoyo" size="medium" maxlength="120" show-word-limit class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 d-flex justify-center mt-4">
                        <div class="col-auto">
                            <el-radio v-model="model.estado" class="radio-green" :label="1">
                                Enable
                            </el-radio>
                        </div>
                        <div class="col-auto">
                            <el-radio v-model="model.estado" class="radio-green" :label="2">
                                Full Disable
                            </el-radio>
                        </div>
                        <div class="col-auto">
                            <el-radio v-model="model.estado" class="radio-green" :label="3">
                                Disable + Text
                            </el-radio>
                        </div>
                    </div>
                    <div class="row mx-0 px-3 mt-3">
                        <div class="col-auto px-0">
                            <button type="button" class="btn-general-small px-4" @click="postFlags">
                                Crear permiso
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
import Service from '~/services/faqs/faqs';
import { extend } from 'vee-validate'

extend('espacios', {
    validate(value){
        var result = /^\S*$/u.test(value);
        return result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar espacios en blanco.'
});
export default {
    data(){
        return {
            flagUnique: true,
            model: {
                nombre: null,
                flag: null,
                descripcion: null,
                texto: null,
                estado: 1,
                idCarpeta: null,
            }
        }
    },
    mounted(){
        this.toggle(this.$route.params.id);
    },
    methods: {
        async postFlags(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const {data} = await Service.postFlags(this.model);
                if(!data.exito){
                    return this.flagUnique = false;
                }
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('nuevo');
                this.$router.back();
                /* this.$refs.modalCrearFeature.toggle(); */
            } catch(error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.$refs.validacion.reset();
            this.flagUnique = true;
            this.model.nombre = null;
            this.model.flag = null;
            this.model.descripcion = null;
            this.model.texto = null;
            this.model.estado = 1;
            this.model.idCarpeta = null;
        },
        toggle(id){
            this.limpiar();
            this.model.idCarpeta = id;
            /* this.$refs.modalCrearFeature.toggle(); */
        }
    }
}
</script>