<template>
    <section class="pt-4">
        <div class="d-flex pl-4 mx-0" style="height:30px;">
            <router-link 
            v-for="(tab, t) in tabs" 
            :key="t" 
            :to="{name: tab.ruta}" 
            class="col-auto d-middle-center px-0 mr-3 f-18" 
            :class="`tab${tab.ruta == $route.name ? '-active': ''} f-600`"
            >
                {{ tab.titulo }}
            </router-link>
        </div>
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            tabs: [
                { titulo: 'Cliente', ruta: 'manager.features-flags.clientes', num: 0, can: '' },
                { titulo: 'Vendedor', ruta: 'manager.features-flags.vendedor', num: 0, can: '' },
                { titulo: 'Delivery', ruta: 'manager.features-flags.delivery', num: 0, can: '' },
                { titulo: 'Picking & Packing', ruta: 'manager.features-flags.picking_packing', num: 0, can: '' },
            ]
        }
    },
    computed: {
        isActive(){
            return this.$route.name
        }
    },
    methods: {
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        },
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none;
}
.menu-lateral{
    position: fixed;
    width: 115px;
    height: 100vh;
    z-index: 0;
    background: var(--gr-red);
}
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    background: linear-gradient(to right, #FF9D32 5%, #FF2525)
}
.tab{
    color: #637381;
    &-active{
        color: #000000;
        border-bottom: 4px solid #000000;
    }
    &:hover{
        color: #000000;
    }
}
</style>