<template>
    <section >
        <div class="row mx-0 px-5 py-3 border-bottom">
            <p class="mb-0 text-muted2 f-600 f-18">
                Términos y condiciones tendero
            </p>
            <p class="mb-0 ml-auto f-15" style="color:#0892DD;">
                Ver los términos y condiciones cliente
            </p>
        </div>
        <div class="overflow-auto custom-scroll" style="height: calc(100vh - 133px)">
            <div class="row mx-0 justify-center pt-5">
                <p class="mb-0 f-22 f-600 text-muted2 text-center">
                    Términos y Condiciones de Uso de Plataforma “YourMarket”
                </p>
            </div>
            <div class="row mx-0 justify-center">
                <p class="mb-0 w-25 f-15">
                    YourMarket S.A
                </p>
                <p class="mb-0 w-25 text-right f-15">
                    Actualizado: hace 1 mes
                </p>
            </div>
            <div class="overflow-auto custom-scroll">
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17 px-4">
                            Información
                        </p>
                    </div>
                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p class="mb-0 f-15 px-4">
                            "YourMarket" es una sociedad constituida conforme a las leyes colombianas, identificada con NIT: 900843898-9, con domicilio en la ciudad de Bucaramanga., que para los efectos de los presentes términos se denominará EL OPERADOR.
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17  px-4">
                            Naturaleza jurídica
                        </p>
                    </div>

                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p class="mb-0 f-15 px-4">
                            Los presentes términos y condiciones de uso regulan la relación contractual de carácter comercial que une a los Consumidores que acceden a la plataforma virtual y al OPERADOR, especialmente en la autorización de uso que otorga éste en favor de aquel.
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17  px-4">
                            Definiciones
                        </p>
                    </div>
                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p class="mb-0 f-15 px-4">
                            Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo electrónico, el telegrama, el télex o el telefax (Ley 527 de 1999 art. 2 lit. a). Comercio Electrónico: Comprende el envío, transmisión, recepción, almacenamiento de mensajes de datos por vía electrónica. Las dudas que surjan respecto de la eficacia y validez de los mensajes de datos y demás actividades vinculadas al comercio electrónico se interpretarán de conformidad con la ley 527 de 1999. Cookies: Cadenas de texto enviadas virtualmente que son almacenadas por el uso de La Plataforma por el Operador, para la consulta de las actividades y preferencias de los usuarios. Consumidores: Toda persona natural que, como destinatario final, use La Plataforma para solicitar por medio de ésta un mandato remunerado, cuyo encargo consiste en la celebración de un contrato de compraventa o cualquier otro tipo de contrato lícito, con el fin de adquirir bienes o servicios. Mandatario: Persona natural que acepta realizar la gestión del encargo solicitado por el Consumidor a través de la Plataforma. El Mandatario actúa por cuenta y riesgo propio y libera de cualquier tipo de responsabilidad que pueda surgir durante la prestación del servicio al Consumidor.
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17  px-4">
                            Objeto
                        </p>
                    </div>
                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p class="mb-0 f-15 px-4">
                            Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI)
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17  px-4">
                            Plataforma Tecnologica
                        </p>
                    </div>
                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p class="mb-0 f-15 px-4">
                            Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI)
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17  px-4">
                            Modificacion
                        </p>
                    </div>
                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p class="mb-0 f-15 px-4">
                            Mensajes de datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="scss">
</style>
