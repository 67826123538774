import axios from 'axios'

const parametros = {
    get_parametros: () => axios.get("x-parametros-manager/get-parametros"),
    get_paises: (params) => axios.get("pais",{ params }),
    get_estados: (pais,params) => axios.get(`${pais}/estado`,{ params }),
    get_ciudades: (estado,params) => axios.get(`${estado}/ciudad`,{ params }),
    crear_parametro: (datos) => axios.post("x-parametros-manager/crear-parametro",datos)
};
export default parametros
