<template>
    <section class="pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 70px)">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 align-items-center mb-3">
                <i class="icon-back cr-pointer f-20 text-black" @click="$router.back()" />
                <div class="col-auto px-2 text-general f-18 f-600">
                    {{ titulo }}
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col">
                    <div class="row mx-0">
                        <div class="box-picture pt-4">
                            <div class="space d-middle-center">
                                <slim-cropper ref="cropImagen" :options="slimOptions" class="border cr-pointer" style="height:110px;width:120px;background:#FFFFFF;" />
                            <!--<i class="icon-plus f-20 text-black" />-->
                            </div>
                            <p class="text-general f-12 text-center mt-2">
                                Foto de perfil
                            </p>
                        </div>
                        <div class="col-4 px-4">
                            <div class="row mx-0">
                                <div class="col-12 px-0 mb-3">
                                    <small class="text-general f-12 pl-3">
                                        Nombre
                                    </small>
                                    <ValidationProvider v-slot="{errors}" rules="required|max:25" name="Nombre">
                                        <el-input v-model="model.nombre" placeholder="Nombre" size="small" class="w-100" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 px-0">
                                    <small class="text-general f-12 pl-3">
                                        Número de celular
                                    </small>
                                    <ValidationProvider v-slot="{errors}" rules="required|numeric|max:18" name="Número de celular">
                                        <el-input v-model="model.telefono" placeholder="Número de celular" size="small" class="w-100" />
                                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 align-items-center">
                        <div class="col-3 px-0 mr-3">
                            <small class="text-black pl-3 f-12">
                                Correo Electrónico
                            </small>
                            <ValidationProvider v-slot="{errors}" rules="required|email|max:70" name="Correo Electrónico">
                                <el-input v-model="model.correo" class="w-100" size="small" placeholder="Correo Electrónico" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto mt-3 pt-1">
                            <el-checkbox v-model="model.notificaciones" class="check-green d-middle" />
                        </div>
                        <div class="col-auto px-0 text-black f-14 mt-3 pt-1">
                            Recibir notificaciones de <br /> correo electronico
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 align-items-center">
                        <div class="col-3 px-0">
                            <small class="text-black pl-3 f-12">
                                Cedis por defecto
                            </small>
                            <ValidationProvider v-slot="{errors}" rules="integer" name="Cedis por defecto">
                                <el-select v-model="model.id_cedis" size="small" class="w-100">
                                    <el-option
                                    v-for="item in cedis"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    >
                                        <span>{{ item.nombre }}</span>
                                        <i v-show="!item.estado" class="icon-eye-off" />
                                    </el-option>
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <small class="text-black pl-3 f-12">
                                Moneda por defecto
                            </small>
                            <ValidationProvider v-slot="{errors}" rules="integer" name="Moneda por defecto">
                                <el-select v-model="model.idm_moneda" size="small" class="w-100">
                                    <el-option
                                    v-for="item in monedas"
                                    :key="item.id"
                                    :label="`Moneda: ${item.nombre} - ${item.sigla}`"
                                    :value="item.id"
                                    />
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 align-items-center">
                        <div class="col-3 px-0">
                            <small class="text-black pl-3 f-12">
                                Contraseña
                            </small>
                            <ValidationProvider v-slot="{errors}" :rules="{required:!modoEditar}" name="Contraseña" vid="confirmation">
                                <el-input v-model="model.password" show-password class="w-100" size="small" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <small class="text-black pl-3 f-12">
                                Confirmar contraseña
                            </small>
                            <ValidationProvider v-slot="{errors}" :rules="{required:!modoEditar, confirmed:'confirmation'}" name="Confirmar contraseña">
                                <el-input v-model="model.password_confirmation" show-password class="w-100" size="small" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <button type="button" class="btn-general-small" @click="save">
                            {{ titulo }}
                        </button>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row mx-0 align-items-center">
                        <p class="col-auto f-18 text-general f-600 pl-0">
                            Cedis administrables
                        </p>
                        <p class="col-auto text-black px-0 ml-auto f-600">
                            {{ cedisPermitidos.length }}
                        </p>
                    </div>
                    <div class="row mx-0 my-2">
                        <div class="col-12">
                            <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar" class="w-100 br-20" size="small" />
                        </div>
                    </div>
                    <div class="row mx-0 border-bottom py-2">
                        <el-checkbox v-model="model.multicedis" class="check-dark" />
                        <div class="col">
                            Administrador multi-Cedis
                        </div>
                    </div>
                    <div v-for="(cedi, index) in cedisPermitidos.filter(el => !buscar || el.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="index" class="row mx-0 py-2">
                        <el-checkbox v-model="cedi.permitido" class="check-dark" />
                        <div class="col">
                            {{ cedi.nombre }}
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Service from '~/services/proyectos/proyectos'
import { mapGetters } from 'vuex'

import { extend } from 'vee-validate';
extend('espacios', {
    validate(value){
        var result = /^\S*$/u.test(value);
        return result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar espacios en blanco.'
});

export default {
    data(){
        return {
            modoEditar: false,
            cambioPass:false,
            cedis: [],
            cedisPermitidos: [],
            model: {
                id: null,
                multicedis: false,
                nombre: null,
                telefono: null,
                correo: null,
                id_cedis: null,
                idm_moneda: null,
                id_proyecto: null,
                notificaciones: false,
                password: null,
                password_confirmation: null,
            },
            slimOptions: {
                label: 'Subir',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            buscar: null,
            checked: false,
            titulo: 'Crear Administrador',
        }
    },

    computed:{
        ...mapGetters({
            monedas: 'cedis/monedas',
        }),

        idProyecto(){
            return this.$usuario.proyecto
        },

        idUser(){
            return Number(this.$route.params.idUser);
        },
    },

    mounted(){
        this.getCedisProyecto();
        this.getCedisUserProyecto()
    },

    methods:{
        async getUser(){
            try {
                const { data } = await Service.getUser(this.idProyecto,this.idUser);
                
                this.titulo= 'Editar Administrador'
                this.model.id = this.idUser;
                this.model.multicedis = parseInt(data.usuario.multicedis) == 1 ? true : false;
                this.model.nombre = data.usuario.nombre;
                this.model.telefono = data.usuario.telefono;
                this.model.correo = data.usuario.correo;
                this.model.id_cedis = data.usuario.id_cedis;
                this.model.idm_moneda = data.usuario.idm_moneda;
                this.model.notificaciones = parseInt(data.usuario.notificaciones) == 1 ? true : false;

                if (data.usuario.foto){
                    this.$refs.cropImagen.set_image(data.usuario.imagen)
                } else {
                    this.$refs.cropImagen.instanciaCrop.remove()
                }

                this.modoEditar = true;
            } catch(e){
                this.error_catch(e)
            }
        },

        async getCedisProyecto(){
            try {
                let params = {
                    id_proyecto: this.idProyecto
                }
                const { data } =  await Service.getCedisProyecto(params)
                this.cedis = data
            } catch (error){
                this.error_catch(error)
            }
        },
        
        async save(){
            try {
                let userCedis = this.cedisPermitidos.filter(d => d.permitido).map(({id}) => ({id}))

                const archivo = this.$refs.cropImagen.instanciaCrop.dataBase64.output
              

                const valid = await this.$refs.validacion.validate()
                if(!valid)return;

                if(!this.model.multicedis){
                    if(userCedis.length < 1)return this.notificacion('Mensaje', 'Debe seleccionar al menos un cedis administrable', 'warning')
                }

                this.model.id_proyecto = this.idProyecto;

                this.model.foto = archivo.image
                this.model.user_cedis = userCedis

                if(this.modoEditar){
                    const {data} = await Service.editarUsuarioAdmin(this.model)
                    this.notificacion('Mensaje', 'Usuario editado', 'success')
                }else{
                    const {data} = await Service.crearUsuarioAdmin(this.model)
                    this.notificacion('Mensaje', 'Usuario creado', 'success')
                }

                this.clear();
                this.$router.push({name:'manager.administradores'});

            } catch (e){
                this.error_catch(e)
            }
        },

        async getCedisUserProyecto(){
            try {
                let params = {
                    id_proyecto: this.idProyecto,
                    id_user: this.idUser
                }
                const { data } =  await Service.getCedisUserProyecto(params)
                this.cedisPermitidos = data
                if(this.idUser){
                    this.getUser();
                } else {
                    this.$refs.cropImagen.instanciaCrop.remove();
                }
            } catch (error){
                this.error_catch(error)
            }
        },

        clear(){
            this.model.id = null;
            this.model.multicedis = false;
            this.model.nombre = null;
            this.model.telefono = null;
            this.model.correo = null;
            this.model.id_cedis = null;
            this.model.idm_moneda = null;
            this.model.id_proyecto = null;
            this.model.notificaciones = false;
            this.model.password = null;
            this.model.password_confirmation = null;
            this.cambioPass = false;
            this.modoEditar = false;
            this.$refs.validacion.reset();
            this.titulo= 'Crear Administrador';
        },
    },
}
</script>

<style lang="scss" scoped>
.box-picture{
    width: 106px;
    .space{
        width: 100%;
        height: 106px;
        border: 1px dashed #DFE4E8;
        background-color: #FFFFFF;
        border-radius: 4px;
    }
}
</style>