<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 border-bottom align-items-center" style="height:50px;">
            <p class="f-600">Política de privacidad</p>            
        </div>
        <div class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100vh - 135px);">
            <div class="row mx-0">
                Crea los términos legales que considere de la naturaleza de este sitio web, esto con el objetivo de protegerse como propietario de una posible exposición legal.
            </div>
            <div class="row mx-0 my-4">
                <div class="col-auto px-2">
                    <p class="f-18 f-600">Política de Protección y Tratamiento de Datos Personales:</p>
                    <p v-if="hayFechaActualizacion">Actualizado: {{ tiempoActualizacion }}</p>
                </div>
                <div class="col-auto pt-1">
                    <el-tooltip class="item" effect="light" content="Crear política" placement="bottom">
                        <div class="bg-grr-general cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="abrirModalCreacion">
                            <i class="icon-plus f-20 text-white" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <draggable v-if="!_.isEmpty(faqs)" :list="faqs" handle=".handle" class="overflow-auto custom-scroll" style="max-height:calc(100vh - 290px);">
                <div v-for="(f, i) in faqs" :key="i">
                    <div class="row mx-0 pr-2 mt-2">
                        <i class="icon-drag my-auto mr-1 f-18 cr-pointer handle" />
                        <div class="col px-0 bg-rosa br-10">
                            <div class="d-middle mx-0">
                                <p class=" f-17 mx-2" v-text="f.titulo" />
                                <el-tooltip class="item" effect="light" content="Editar término" placement="bottom">
                                    <div class="btn-edit d-middle br-5 text-white ml-auto cr-pointer m-1" style="width:32px;height:32px;" @click="abrirModalEdicion(f)">
                                        <i class="icon-pencil-outline f-22" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip class="item" effect="light" content="Eliminar término" placement="bottom">
                                    <div class="btn-delete d-middle br-5 text-white cr-pointer m-1" style="width:32px;height:32px;" @click="abrirModalEliminacion(f)">
                                        <i class="icon-trash-empty f-22" />
                                    </div>
                                </el-tooltip>
                                <el-switch
                                v-model="f.estado"
                                class="my-auto switch-red"
                                active-color="red"
                                inactive-color="#DBDBDB"
                                @change="actualizarEstado(f)"
                                />
                                <i class="mx-2 cr-pointer f-22" :class="colocarIconoColapsado(f.colapsado)" @click="colapsar(f)" />
                            </div>
                        </div>
                    </div>
                    <div v-show="!f.colapsado" class="row mx-0 px-3 my-2">
                        <div class="col">
                            <p style="white-space:break-spaces;" v-text="_.get(f, 'texto.texto')" />
                        </div>
                    </div>
                </div>
            </draggable>
            <sinDatos v-else icon="list" mensaje="Aún no se han creado políticas" />
        </div>
        <modalGestionarFaqs
        ref="modalGestionarFaqs"
        :tipo="tipo"
        :modo-edicion="estaEditando"
        :datos="itemSeleccionado"
        @crear="crearFaq"
        @actualizar="actualizarFaq"
        />
        <modalEliminar ref="modalEliminarFaq" titulo="Eliminar término" mensaje="¿Desea eliminar el término?" @eliminar="eliminarFaq" />
    </section>
</template>

<script>
import faqsMixin from '~/mixins/faqs/gestion'

const TIPO = 3
export default {
    name: 'InformacionPoliticaPrivacidad',
    components: {
        modalGestionarFaqs: () => import('./partials/modalGestionarFaqs')
    },
    mixins: [ faqsMixin ],
    data(){
        return {
            tipo: TIPO
        }
    },
    mounted(){
        this.consultarFaqs(TIPO, 0)
    },
    methods: {
        abrirModalCreacion(){
            this.limpiarElementoSeleccionado()
            this.abrirModalGestion()
        },
        abrirModalEdicion(item){
            this.seleccionarItemComun(item)
            this.abrirModalGestion()
        },
        abrirModalGestion(){
            this.$refs.modalGestionarFaqs.toggle()
        },
        abrirModalEliminacion(item){
            this.seleccionarItemComun(item)
            this.alternarModalEliminacion()
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
$border: 5px;
.border-b-red{
    border-width: 3px;
    border-style: solid;
    border-image: var(--gr-r-red) 0 0 100%;
}
</style>
