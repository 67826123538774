<template>
    <section>
        <div class="row mx-0 mt-4">
            <div class="col-12 bg-white br-t-8">
                <div class="col px-0 mt-3">
                    <div class="row mx-0 align-items-center">
                        <div class="col-auto text-general f-18 f-600">
                            Lista de administradores
                        </div>
                        <button type="button" class="btn-general-small px-3 ml-auto" @click="$router.push({name: 'manager.administradores.crear'})">
                            Crear Administrador
                        </button>
                        <div class="col-auto">
                            <el-input v-model="buscar" placeholder="Buscar" size="small" prefix-icon="icon-search" />
                        </div>
                    </div>
                </div>
                <el-table :data="dataTable.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()))" class-header="text-black f-600 f-16">
                    <el-table-column
                    width="110"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0">
                                <img :src="scope.row.imagen" class="obj-cover rounded-circle" width="44" height="44" />
                                <div v-if="scope.row.estado == 0" class="col-auto px-2 d-middle-center">
                                    <el-tooltip content="Inactivo" placement="bottom" effect="light">
                                        <i class="icon-eye-off f-20 text-black" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="nombre"
                    label="Nombre"
                    />
                    <el-table-column
                    prop="correo"
                    label="Correo electrónico"
                    />
                    <el-table-column
                    prop="cedis"
                    label="Cedis"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="moneda"
                    label="Moneda por defecto"
                    />
                    <el-table-column
                    width="110"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0">
                                <div class="col-auto">
                                    <el-popover placement="bottom" effect="light">
                                        <div class="row mx-0 p-2 f-14 item-popover" @click="goEdit(scope.row.id)">
                                            Editar
                                        </div>
                                        <div v-if="scope.row.estado == 1" class="row mx-0 p-2 f-14 item-popover" @click="changeState(scope.row.id, 0)">
                                            Inactivar
                                        </div>
                                        <div v-if="scope.row.estado == 0" class="row mx-0 p-2 f-14 item-popover" @click="changeState(scope.row.id, 1)">
                                            Activar
                                        </div>
                                        <div class="row mx-0 p-2 f-14 item-popover" @click="confirmDelete(idEliminar = scope.row.id)">
                                            Eliminar
                                        </div>
                                        <i slot="reference" class="icon-vertical-dots f-20 text-black" />
                                    </el-popover>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <modalConfirmar ref="modalDeleteAdmin" titulo="Eliminar administrador" icon="checked" mensaje="¿Desea eliminar este administador?" @guardar="deleteAdmin" />
    </section>
</template>

<script>
import Service from '~/services/proyectos/proyectos'

export default {
    data(){
        return {
            buscar: '',
            dataTable: [],
            idEliminar: null,
            /*dataTable: [
                {
                    img: 'https://www.caritas.org.mx/wp-content/uploads/2019/02/cualidades-persona-humanitaria.jpg',
                    nombre: 'Kasandra Mora',
                    correo_electronico: 'kasandramora@gmail.com',
                    cedis: 10,
                    moneda_defecto: 'Peso Colommbiano (COP)'
                }
            ],*/
            dataColumns: [
                {  titulo: 'Nombre', valor: 'nombre', class: 'text-center' },
                { titulo: 'Correo Electronico', valor: 'correo', class: 'text-center' },
                { titulo: 'Cedis', valor: 'cedis', class: 'text-center' },
                { titulo: 'Moneda por defecto', valor: 'moneda_defecto', class: 'text-center' }
            ]
        }
    },

    mounted(){
        this.getManagers();
    },

    methods:{
        async getManagers(){
            try {
                const idProyecto = this.$usuario.proyecto
                const {data} = await Service.getManagers(idProyecto);
                this.dataTable = data.managers;
            } catch(e){
                this.error_catch(e)
            }
        },

        confirmDelete(){
            this.$refs.modalDeleteAdmin.toggle()
        },

        async deleteAdmin(){
            try {
                const {data} = await Service.eliminarUserAdmin({id: this.idEliminar, id_proyecto: this.$usuario.proyecto})
                let index = this.dataTable.findIndex((item) => parseInt(item.id) === parseInt(this.idEliminar));

                this.dataTable.splice(index, 1);
                this.notificacion('Mensaje', 'Usuario eliminado', 'success')
                this.idEliminar = null;
            } catch (e){
                this.error_catch(e)
            }
        },

        async changeState(idUser, state){
            try {
                const model = {
                    state: state,
                    id_proyecto: this.$usuario.proyecto
                };
                const {data} = await Service.changeStateUser(idUser, model)
                let index = this.dataTable.findIndex((item) => parseInt(item.id) === parseInt(idUser));
                this.dataTable[index].estado = state;
                this.notificacion('Mensaje', data.mensaje, data.tipo)
            } catch(e){
                this.error_catch(e)
            }
        },

        goEdit(idEdit){
            this.$router.push({name:'manager.administradores.editar', params:{idUser: idEdit}});
        }
    },
}
</script>
<style lang="scss" scoped>
.item-popover{
    border-radius: 4px;
    cursor: pointer;
    &:hover{
        background-color: #F6F9FB;
    }
}
</style>