<template>
    <section class="aspectos Correo">
        <div class="row mx-0 border-bottom py-2 pl-5 px-3 text-muted2 f-600">
            Aspecto correos electrónicos
        </div>
        <div class="pl-5 px-3 overflow-auto custom-scroll" style="height:calc(100vh - 105px);">
            <div class="row mx-0 text-muted2 f-15 mt-4">
                Imagen para la cabecera del correo
            </div>
            <div class="row mx-0 mt-2">
                <slim-cropper ref="crop_header" :options="slimOptions" class="border" style="height:180px;background:#F8F9FF;width:calc(100vw - 850px);">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col-12 px-0">
                    Información para el pie de página del correo
                </div>
                <div class="col-4 mt-3 pr-1 pl-0">
                    <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="página web">
                        <label class="pl-3 text-muted2 f-15" v-text="`Página Web`" />
                        <el-input v-model="pagina_web" class="w-100" @change="crear_parametro(valid,22,$event)" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-4 mt-3 px-1">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max:200" name="dirección">
                        <label class="pl-3 text-muted2 f-15" v-text="`Dirección`" />
                        <el-input v-model="direccion" class="w-100" @change="crear_parametro(valid,23,$event)" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-4 mt-3 px-1">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max:200" name="télefono">
                        <label class="pl-3 text-muted2 f-15" v-text="`Télefono`" />
                        <el-input v-model="telefono" class="w-100" @change="crear_parametro(valid,24,$event)" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-4 mt-3 pr-1 pl-0">
                    <ValidationProvider v-slot="{errors}" rules="required" name="país">
                        <label class="pl-3 text-muted2 f-15" v-text="`País`" />
                        <el-select v-model="idm_pais" class="w-100" filterable remote :remote-method="remoteMethodPais" :loading="loading">
                            <el-option
                            v-for="item in paises"
                            :key="item.id"
                            :label="item.pais"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-4 mt-3 px-1">
                    <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                        <label class="pl-3 text-muted2 f-15" v-text="`Estado`" />
                        <el-select v-model="idm_estado" class="w-100" filterable remote :remote-method="remoteMethodEstado" :loading="loading">
                            <el-option
                            v-for="item in estados"
                            :key="item.id"
                            :label="item.estado"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-4 mt-3 px-1">
                    <ValidationProvider v-slot="{errors, valid}" rules="required" name="ciudad">
                        <label class="pl-3 text-muted2 f-15" v-text="`Ciudad`" />
                        <el-select v-model="idm_ciudad" class="w-100" filterable remote :remote-method="remoteMethodCiudad" :loading="loading" @change="crear_parametro(valid,25,$event)">
                            <el-option
                            v-for="item in ciudades"
                            :key="item.id"
                            :label="item.ciudad"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col-12 px-0 f-17 text-muted2">
                    Redes sociales
                </div>
                <div class="col-5 col-sm-6 mt-3">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-facebook f-22 text-gr-general" />
                        <div class="col">
                            <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="facebook">
                                <label class="pl-3 text-muted2 f-15" v-text="`Facebook`" />
                                <el-input v-model="facebook" class="w-100" @change="crear_parametro(valid,31,$event)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-sm-6 mt-3">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-youtube-play f-22 text-gr-general" />
                        <div class="col">
                            <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="youtube">
                                <label class="pl-3 text-muted2 f-15" v-text="`Youtube`" />
                                <el-input v-model="youtube" class="w-100" @change="crear_parametro(valid,32,$event)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-sm-6 mt-3">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-instagram f-22 text-gr-general" />
                        <div class="col">
                            <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="instagram">
                                <label class="pl-3 text-muted2 f-15" v-text="`Instagram`" />
                                <el-input v-model="instagram" class="w-100" @change="crear_parametro(valid,33,$event)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-sm-6 mt-3">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-twitter f-22 text-gr-general" />
                        <div class="col">
                            <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="twitter">
                                <label class="pl-3 text-muted2 f-15" v-text="`Twitter`" />
                                <el-input v-model="twitter" class="w-100" @change="crear_parametro(valid,34,$event)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-5 col-sm-6 mt-3">
                    <div class="row mx-0 align-items-center">
                        <i class="icon-whatsapp f-22 text-gr-general" />
                        <div class="col">
                            <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="whatsapp">
                                <label class="pl-3 text-muted2 f-15" v-text="`Whatsapp`" />
                                <el-input v-model="whatsapp" class="w-100" @change="crear_parametro(valid,35,$event)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-5">
                <div class="col-12 px-0 f-600">
                    Previsualizacion del pie de página
                </div>
                <div class="col-12 m-3">
                    <div class="br-3 bg-whitesmoke p-2" style="width:calc(100vw - 850px);">
                        <div class="row mx-0">
                            <div class="col text-left">
                                <p class="">
                                    Copyright © 2021 {{ nombre_proyecto }}, Todos los derechos reservados.
                                    Estas recibiendo este correo porque te suscribiste a la comunidad {{ nombre_proyecto }}.
                                </p>
                                <p class="text-gr-general f-14 mt-3">
                                    {{ pagina_web }}
                                </p>
                            </div>
                            <div class="col text-right">
                                <div class="row mx-0">
                                    <p class="col text-muted2 f-15 f-600">
                                        Nuestra direccion es:
                                    </p>
                                </div>
                                <div class="row mx-0">
                                    <p class="col tres-puntos" style="min-height:24px;">
                                        {{ direccion }}
                                    </p>
                                </div>
                                <div class="row mx-0">
                                    <p class="col tres-puntos" style="min-height:24px;">
                                        {{ `${ciudad}, ${pais}` }}
                                    </p>
                                </div>
                                <div class="row mx-0">
                                    <p class="col tres-puntos" style="min-height:24px;">
                                        {{ telefono }}
                                    </p>
                                </div>
                                <div class="row mt-3 justify-content-end">
                                    <i v-if="facebook" class="icon-facebook f-22 text-gr-general" />
                                    <i v-if="youtube" class="icon-youtube-play f-22 text-gr-general" />
                                    <i v-if="instagram" class="icon-instagram f-22 text-gr-general" />
                                    <i v-if="twitter" class="icon-twitter f-22 text-gr-general" />
                                    <i v-if="whatsapp" class="icon-whatsapp f-22 text-gr-general" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

// import Localizaciones from "~/services/proyectos/localizaciones";
import Parametros from "~/services/x_parametros"
import Aws from '~/services/aws'

export default {
    data(){
        /**
         * Esto se usa para que guarde el this de vue para usarlo en el slim
         */
        let vm = this
        return {
            loading: false,
            slimOptions: {
                ratio: '8:2',
                label:'Subir imagen',
                // minSize: { width: 100, height: 10 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                didRemove: function(a){
                    vm.evento(a.output.image)
                    vm.first_time = false
                },
                didSave: function(a){
                    vm.evento(a.output.image)
                    vm.first_time = false
                }
            },
            paises: [],
            estados: [],
            ciudades: [],
            nombre_proyecto: '',
            pagina_web: '',
            direccion: '',
            telefono: '',
            idm_pais: null,
            idm_estado: null,
            idm_ciudad: null,
            facebook: '',
            youtube: '',
            instagram: '',
            twitter: '',
            whatsapp: '',
            pais: '',
            estado: '',
            ciudad: '',
            first_time: true,
            select_first: false,
        }
    },
    watch: {
        idm_pais(pais){
            this.consultarEstados()            
        },
        idm_estado(estado){
            this.consultarCiudades()
        }
    },
    async mounted(){
        await this.get_parametros()
    },
    methods: {
        async get_parametros(){
            try {
                const { data } = await Parametros.get_parametros()
                this.$validar(data)
                this.nombre_proyecto = data.parametros.nombre_proyecto
                this.pagina_web = data.parametros.pagina_web
                this.direccion = data.parametros.direccion
                this.telefono = data.parametros.telefono
                this.idm_ciudad = data.parametros.idm_ciudad
                this.facebook = data.parametros.facebook
                this.youtube = data.parametros.youtube
                this.instagram = data.parametros.instagram
                this.twitter = data.parametros.twitter
                this.whatsapp = data.parametros.whatsapp
                this.pais = data.parametros.pais
                this.estado = data.parametros.estado
                this.ciudad = data.parametros.ciudad
                this.idm_pais = data.parametros.idm_pais
                await this.consultarPaises()
                this.idm_estado = data.parametros.idm_estado
                await this.consultarEstados()
                this.idm_ciudad = data.parametros.idm_ciudad
                await this.consultarCiudades()
                if (data.parametros.header !== null){
                    this.$refs.crop_header.set_image(`https://${Aws.Bucket}.s3.amazonaws.com/${data.parametros.header}`)
                } else {
                    this.$refs.crop_header.instanciaCrop.remove()
                }
                this.select_first = true
            } catch (e){
                this.error_catch(e)
            }
        },
        async crear_parametro(valid, tipo, dato){
            try {
                if (valid){
                    let datos = {
                        tipo: tipo,
                        texto: dato
                    }
                    const {data} = await Parametros.crear_parametro(datos)
                    this.notificacion('', data.mensaje, 'success')
                    if (tipo == 25){
                        this.firstTime = true
                        this.get_parametros()
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async remoteMethodPais(texto){
            this.delay(this.consultarPaises(texto))
        },
        async consultarPaises(texto = null){
            try {
                const params = {
                    query: texto,
                    id_default: this.idm_pais,
                }
                this.loading = true
                const {data} = await Parametros.get_paises(params)
                this.loading = false
                this.paises = data
            } catch (e){
                    this.error_catch(e)
            }
        },
        async remoteMethodEstado(texto){
            this.delay(this.consultarEstados(texto))
        },
        async consultarEstados(texto = null){
            try {
                if (this.select_first) {
                    this.idm_estado = null       
                    this.idm_ciudad = null  
                    this.estados = []     
                    this.ciudades = []     
                }
                if (this.idm_pais != null){
                    const params = {
                        query: texto,
                        id_default: this.idm_estado,
                    }
                    this.loading = true
                    const {data} = await Parametros.get_estados(this.idm_pais, params)
                    this.loading = false
                    this.estados = data.data
                }
                
            } catch (e){
                    this.error_catch(e)
            }
        },
        async remoteMethodCiudad(texto){
            this.delay(this.consultarCiudades(texto))
        },
        async consultarCiudades(texto = null){
            try {
                if (this.select_first) {
                    this.idm_ciudad = null   
                    this.ciudades = []
                }
                if (this.idm_estado != null){
                    const params = {
                        query: texto,
                        id_default: this.idm_ciudad,
                    }
                    this.loading = true
                    const {data} = await Parametros.get_ciudades(this.idm_estado, params)
                    this.loading = false
                    this.ciudades = data.data
                }
                
            } catch (e){
                this.error_catch(e)
            }
        },
        async evento(val){
            try {

                if (this.first_time){
                    return
                }

                let datos = {
                    tipo: 21,
                    texto: val,
                    max: 800
                }
                const {data} = await Parametros.crear_parametro(datos)
                this.$validar(data)
                this.notificacion('', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.nombre_1{
    height: 97px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 25px;
}
</style>
