<template>
    <section class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 44px)">
        <div class="row mx-0">
            <div class="col ml-5 mt-3">
                <p class="f-28 text-black f-600 mb-2">
                    {{ project.nombre }}
                </p>
                <p class="text-general f-14 f-300 mb-4" style="max-width:580px;">
                    {{ project.descripcion }}
                </p>
                <p class="f-28 text-general f-600 mb-3">
                    Textos de roles
                </p>
                <p class="f-18 text-general my-3">
                    <span class="f-600">
                        Empresa:
                    </span>
                    {{ project.empresa }}
                </p>
                <p class="f-18 text-general my-3">
                    <span class="f-600">
                        Vendedor:
                    </span>
                    {{ project.vendedor }}
                </p>
                <p class="f-18 text-general my-3">
                    <span class="f-600">
                        Cliente:
                    </span>
                    {{ project.cliente }}
                </p>
				<p class="f-18 text-general my-3">
                    <span class="f-600">
                        Identificacion user:
                    </span>
                    {{ project.idCliente }}
                </p>
            </div>
            <div class="col-auto">
                <button v-if="!_.isEmpty(project)" type="button" class="btn-general-small mr-2 ml-auto" @click="goEdit">
                    Editar información
                </button>
            </div>
        </div>
        <!-- Partials -->
        <modal-editar-informacion ref="modalEditarInformacion" @update="getData" />
    </section>
</template>

<script>
import Service from '~/services/proyectos/proyectos'

export default {
    components:{
        modalEditarInformacion: () => import('./partials/modalEditarInformacion.vue')
    },

    data(){
        return {
            project: {},
        }
    },

    mounted(){
        this.getData();
    },

    methods:{
        async getData(){
            try {
                const {data} = await Service.getDataProject(this.$usuario.proyecto);
                this.project = data.project;
            } catch(e){
                this.error_catch(e)
            }
        },

        goEdit(){
            if(_.isEmpty(this.project))return;
            this.$refs.modalEditarInformacion.showModal(this.project);
        },
    },
}
</script>