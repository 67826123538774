<template>
    <section class="logos-sistema">
        <div class="row mx-0 border-bottom py-2 pl-5 px-3">
            <div class="col-auto px-0 text-muted2 f-600">
                Logos del sistema
            </div>
            <div class="col-auto ml-auto">
                Medida mínima 70x70px
            </div>
        </div>
        <div class="row mx-0 pl-5 px-3 mt-4">
            <div class="col-7 px-0">
                <ValidationProvider v-slot="{errors, valid}" rules="max:200" name="nombre proyecto">
                    <label class="pl-3 text-muted2 f-15" v-text="`Nombre del Proyecto`" />
                    <el-input v-model="nombre_proyecto" class="w-100" @change="crear_parametro(valid,11,$event)" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row mx-0 pl-5 px-3 pt-5">
            <div class="col col-lg-auto px-3">
                <p class="f-17 text-muted2 f-600 px-0 text-md-center">
                    Web
                </p>
            </div>
            <div class="col-auto px-3">
                <slim-cropper ref="crop_logo_color" :options="slimOptions1" class="border cr-pointer bg-white" style="height:162px;width:162px;border-radius:12px;">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <p class="text-center f-12 py-2">
                    Logo Color
                </p>
            </div>
            <div class="col-auto px-3">
                <slim-cropper ref="crop_logo_blanco" :options="slimOptions2" class="border cr-pointer bg-gr-general text-white" style="height:162px;width:162px;border-radius:12px;">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <p class="text-center f-12 py-2">
                    Logo Blanco
                </p>
            </div>
        </div>
        <div class="row mx-0 pl-5 px-3 pt-4 mt-5">
            <div class="col col-lg-auto">
                <p class="f-17 text-muted2 f-600 px-0">
                    Movil
                </p>
            </div>
            <div class="col-auto px-2">
                <slim-cropper ref="crop_logo_app" :options="slimOptions3" class="border cr-pointer bg-white" style="height:162px;width:162px;border-radius:12px;">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <p class="text-center f-12 py-2">
                    Logo Color
                </p>
            </div>
        </div>
    </section>
</template>
<script>
import Parametros from "~/services/x_parametros"
import Aws from '~/services/aws'

export default {
    data(){
        /**
         * Esto se usa para que guarde el this de vue para usarlo en el slim
         */
        let vm = this
        return {
            nombre_proyecto: '',
            slimOptions1: {
                ratio: '1:1',
                label:'Subir imagen',
                minSize: { width: 70, height: 70 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                didRemove: function(a){
                    vm.evento(a.output.image,12,vm.firstTime1)
                    vm.firstTime1 = false
                },
                didSave: function(a){
                    vm.evento(a.output.image,12,vm.firstTime1)
                    vm.firstTime1 = false
                }
            },
            slimOptions2: {
                ratio: '1:1',
                label:'Subir imagen',
                minSize: { width: 70, height: 70 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                didRemove: function(a){
                    vm.evento(a.output.image,13,vm.firstTime2)
                    vm.firstTime2 = false
                },
                didSave: function(a){
                    vm.evento(a.output.image,13,vm.firstTime2)
                    vm.firstTime2 = false
                }
            },
            slimOptions3: {
                ratio: '1:1',
                label:'Subir imagen',
                minSize: { width: 70, height: 70 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                didRemove: function(a){
                    vm.evento(a.output.image,14,vm.firstTime3)
                    vm.firstTime3 = false
                },
                didSave: function(a){
                    vm.evento(a.output.image,14,vm.firstTime3)
                    vm.firstTime3 = false
                }
            },
            firstTime1: true,
            firstTime2: true,
            firstTime3: true,
        }
    },
    mounted(){
        this.get_parametros()
    },
    methods: {
        async get_parametros(){
            try {
                const { data } = await Parametros.get_parametros()
                this.$validar(data)
                this.nombre_proyecto = data.parametros.nombre_proyecto
                if (data.parametros.logo_color !== null){
                    this.$refs.crop_logo_color.set_image(`https://${Aws.Bucket}.s3.amazonaws.com/${data.parametros.logo_color}`)
                } else {
                    this.$refs.crop_logo_color.instanciaCrop.remove()
                }
                if (data.parametros.logo_blanco !== null){
                    this.$refs.crop_logo_blanco.set_image(`https://${Aws.Bucket}.s3.amazonaws.com/${data.parametros.logo_blanco}`)
                } else {
                    this.$refs.crop_logo_blancoinstanciaCrop.remove()
                }
                if (data.parametros.logo_app !== null){
                    this.$refs.crop_logo_app.set_image(`https://${Aws.Bucket}.s3.amazonaws.com/${data.parametros.logo_app}`)
                } else {
                    this.$refs.crop_logo_apper.instanciaCrop.remove()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async crear_parametro(valid, tipo, dato){
            try {
                if (valid){
                    let datos = {
                        tipo: tipo,
                        texto: dato
                    }
                    const {data} = await Parametros.crear_parametro(datos)
                    this.$validar(data)
                    this.notificacion('', data.mensaje, 'success')
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async evento(val,tipo,valid){
            try {
                if (valid) return

                let datos = {
                    tipo: tipo,
                    texto: val,
                    max: 150
                }
                const {data} = await Parametros.crear_parametro(datos)
                this.$validar(data)
                this.notificacion('', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
